@import url('https://fonts.googleapis.com/css?family=Open+Sans|Playfair+Display+SC');

.App {
  padding: 1em;
}

label {
  display: inline-block;
  width: 100px;
}

header {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

h1 {
  font-family: "Playfair Display SC, serif";
  text-align: center;
  font-size: 64px;
}

/* typography */

html {
  font-family: 'helvetica neue', sans-serif;
}

thead th, tfoot th {
  font-family: 'Rock Salt';
}

th {
  letter-spacing: 2px;
  padding: 10px;
}

td {
  letter-spacing: 1px;
  overflow-x:hidden;
  overflow-y:hidden;
  font-size: 12px;
  padding: 10px;
  text-align: center;
}

tr:nth-child(odd){
  background-color: #F2F4FF;
}

/* table */

table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  overflow:hidden;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.content_td p {
  max-width: 100%;
  max-height: 100px;
  overflow-y: auto;
  text-overflow: ellipsis;
}

img {
  object-fit: scale-down;
  width: 100%;
  height: 100%;
}


/* Column Size for thead */


/* Column size for thead and tbody */
th:nth-child(1),
th:nth-child(2),
th:nth-child(3),
th:nth-child(6),
td:nth-child(1),
td:nth-child(2),
td:nth-child(3),
td:nth-child(6){
  width: 10%;
}

th:nth-child(4),
th:nth-child(5),
td:nth-child(4),
td:nth-child(5){
width: 30%;
}

/* Form input styles */

.formInputs{
  justify-content: center;
  width: 80%;
}